/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import renderStructuredText from '../util/StructuredTextToHtml'

export default function PreviewTekst({ tekstData }: any) {
  return (
    <div
      className="container"
      css={css`
        max-width: 800px;
        margin: 0 auto 100px;
        display: flex;
        flex-direction: column;
        align-items: start;
      `}
    >
      {tekstData.titel && <h1>{tekstData.titel}</h1>}
      {tekstData.datum && (
        <div
          css={css`
            margin-top: -20px;
          `}
        >
          {tekstData.datum}
        </div>
      )}

      {tekstData.tekstPreview && (
        <div
          dangerouslySetInnerHTML={{
            __html: renderStructuredText(tekstData.tekstPreview.value),
          }}
        />
      )}
      <Link
        to={`/woord/${tekstData.titel.replace(/\W/g, '')}`}
        css={css`
          text-decoration: underline;
          cursor: pointer;
        `}
      >
        Verder lezen
      </Link>
      {tekstData.afbeelding?.gatsbyImageData && tekstData.afbeeldingInPreview && (
        <GatsbyImage
          image={tekstData.afbeelding.gatsbyImageData}
          alt=""
          css={css`
            margin-top: 40px;
          `}
        />
      )}
    </div>
  )
}
