import Layout from '../components/Layout'
import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { graphql } from 'gatsby'
import PreviewTekst from '../components/PreviewTekst'
import SEO from '../components/Seo'

export function Woord({ data }: any) {
  data.allDatoCmsTeksten.edges.sort((a, b) => b.node.ranking - a.node.ranking)

  return (
    <Layout>
      <SEO title="Woord" />
      <ResponsiveMasonry columsCountBreakPoints={{ 300: 1, 850: 2, 1450: 3 }}>
        <Masonry gutter={'80px'}>
          {data.allDatoCmsTeksten.edges.map((tekst: any, index: number) => {
            return <PreviewTekst tekstData={tekst.node} key={index} />
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Layout>
  )
}

export default Woord

export const query = graphql`
  query woordQuery {
    allDatoCmsTeksten {
      edges {
        node {
          afbeeldingInPreview
          afbeelding {
            gatsbyImageData
          }
          datum
          titel
          volledigeTekst {
            value
          }
          tekstPreview {
            value
          }
          ranking
        }
      }
    }
  }
`
